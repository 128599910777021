  
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
  }
  @import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
