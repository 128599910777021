@import url('https://fonts.googleapis.com/css2?family=Urbanistb:wght@700&display=swap');

.center{
    padding-top: 8rem;
}
.footer-upward-div{
    width: 70%;
    background-color:#FFC000;
    margin-left: 15%;
    position: relative;
    margin-top: 10vh;
    top: 15vh;
}
.footer-flex-div{
    padding: 40px 20px;
    display: flex;
}
.flex-left{
    font-family: Urbanist;
    font-size: 2.25rem;
    width: 70%;
}
.flex-right{
    font-family: Urbanist;
    width: 30%;
    color: white;
    margin-top: 8vh;
}
.footer-button{
    padding: 10px 30px;
    background: #6C7D02;
    border-radius: 86px;
}
.footer-below-div{
    width: 100%;
    background-color: #212121;
}
.footer-heading{
    color: white;
    font-family: Urbanist;
    font-size: 3.75rem;

}
.footer-text{
    color: white;
    font-family: Urbanist;
    font-size: 0.75rem;
    font-weight: 300;
    margin-top: 2vh;
}
.footer-button2{
    padding: 10px 30px;
    background: #FFC000;
    border-radius: 86px;
    color: black;
}

@media screen and (max-width:1122px) {
    .footer-flex-div{
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
    }
    .flex-left{
        font-family: Urbanist;
        font-size: 2.25rem;
        width: 100%;
    }
    .flex-right{
        font-family: Urbanist;
        font-size: 1rem;
        width: 100%;
    }
    
}
@media screen and (max-width:672px) {
    .footer-flex-div{
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
    }
    .flex-left{
        font-family: Urbanist;
        font-size: 1rem;
        width: 100%;
    }
    .flex-right{
        font-family: Urbanist;
        font-size: 0.5rem;
        width: 100%;
    }
    .footer-upward-div{
        width: 70%;
        background-color:#FFC000;
        margin-left: 15%;
        position: relative;
        top: 15vh;
    }
    .center{
        padding-top: 6rem;
    }
    .footer-heading{
        color: white;
        font-family: Urbanist;
        font-size: 1.5rem;
    
    }
    .footer-button{
        margin-top: -5vh;
        padding: 10px 30px;
        background: #6C7D02;
        border-radius: 86px;
    }
    
    
}

@media (min-width:784px) and (max-width:1118px) {
    
    .footer-upward-div{
        width: 70%;
        background-color:#FFC000;
        margin-left: 15%;
        position: relative;
        top: 15vh;
    }
    .center{
        padding-top: 6rem;
    }
}

@media (min-width:673px) and (max-width:783px) {
    
    .footer-flex-div{
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
    }
    .flex-left{
        font-family: Urbanist;
        font-size: 2rem;
        width: 100%;
    }
    .flex-right{
        font-family: Urbanist;
        font-size: 0.5rem;
        width: 100%;
    }
    .footer-upward-div{
        width: 70%;
        background-color:#FFC000;
        margin-left: 15%;
        position: relative;
        top: 15vh;
    }
    .center{
        padding-top: 6rem;
    }
    .footer-heading{
        color: white;
        font-family: Urbanist;
        font-size: 2.5rem;
    
    }
    .footer-button{
        margin-top: -5vh;
        padding: 10px 30px;
        background: #6C7D02;
        border-radius: 86px;
    }
}


.bottom-last{
    height: max-content;
    background: #212121;
}
.text-b{
    float: left;
    color: #FFFFFF;
    padding: 10px 10px;
    padding-left: 10%;
    font-weight: normal;
}
.bottom-last-right{
padding-left: 50%;
}

@media screen and (max-width:600px) {


    .bottom-last{
        height: max-content;
        background: #212121;

        display: flex;
        flex-direction: column;
    }

    .bottom-last-right{
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding-left: 0;

    }
}
@media (min-width:628px) and (max-width:1100px) {


    .bottom-last{
        height: max-content;
        background: #212121;

        display: flex;
    }
    .text-b{
        width: 70%;
    }

    .bottom-last-right{
        float: right;
        width: 30%;
        margin-right: 25%;

    }
}