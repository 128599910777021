@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Urbanistr&display=swap');

.texthead{
    text-align: center;
    margin-top: 19rem;
    font-family: 'Quicksand', sans-serif;
    font-size: 2rem;

    @media screen and (max-width:500px) {
        margin-top: 10rem;

    }
}

.container {
    padding-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .containerleft {
    width: 40%;

    .heading {
      color: #5d7202;
      font-size: 2rem;
      font-weight: 700;
    }

    .text {
      color: #424242;
      font-size: 1.5rem;
      width: 80%;
      margin-bottom: 20px;
      transition: opacity 0.3s ease;
    }

    .controls {
      display: flex;
      font-size: 2rem;
      color: #bdbdbd;
      margin-top: 5px;

      .prev:hover,
      .next:hover {
        color: #5d7202;
        transition: all ease 500ms;
        cursor: pointer;
      }
    }
  }

  .containerright {
    width: 40%;

    .image {
      position: relative;

      img {
        width: auto;
        height: 60vh;
        transition: opacity 0.3s ease;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;

    .containerleft,
    .containerright {
      width: 100%;
      text-align: center;
    }

    .containerleft {
      margin-bottom: 2rem;

      .heading {
        font-size: 1.5rem;
      }

      .text {
        font-size: 1.2rem;
        width: 100%;
      }

      .controls {
        justify-content: center;
        margin-top: 1rem;
      }
    }

    .containerright {
      .image {
        img {
          height: auto;
          max-width: 100%;
        }
      }
    }
  }
}

center {
  span {
    font-size: 1.5rem;
  }
  font-size: 18px;
  font-weight: 500;
}
