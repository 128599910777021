.container{
width: 80%;
margin-left: 10%;
margin-right: 10%;
text-align: justify;

.heading{
    font-size: 3rem;
    font-family: 'Quicksand', sans-serif;
    font-weight: 1000;
    margin-top: 10vh;
    @media screen and (max-width: 991px) {
        margin-top: 15vh;
      }

}
.para{
    margin-top: 5vh;
    font-family: "Nunito", sans-serif;
    text-align: justify;    

}

    img{
        margin-top: 20px;
        width: 60%;
    }
}


.container3{
    width: 80%;
    display: flex;
    margin-left: 10%;
    padding-top: 5vh;
    padding-bottom: 5vh;
    @media screen and (max-width: 991px) {
        margin-left: 5%;
        display: flex;
        flex-direction: column;
      }
    .section1{
        padding: 20px;
        width: 50%;
        @media screen and (max-width: 991px) {
            width: 100%;
          }
        img{
            width: 100%;
        }
        
    }
    .section2{
        width: 50%;
        @media screen and (max-width: 991px) {
            width: 110%;
          }

        .h1{
            font-size: 1.5rem;
            font-weight: 1000;
            font-family: 'Quicksand', sans-serif;
            text-align: justify;
            color: #6c7d02;
        }

        .para{
             font-size: 1rem;
             font-family: "Nunito", sans-serif;
             font-weight: 400;
             text-align: justify;

        }
    }
    .para{
        font-family: "Nunito", sans-serif;

    }
}