/*------Testimonial--------*/
.test-sec{
  margin: 4rem auto;
  text-align: center;
  background-color: #f5f5f5;
  padding-top: 3rem;
  padding-bottom: 3rem;
  
}

.test-title{
  font-family: 'Noto Serif';
font-style: normal;
font-weight: 600;
font-size: 1.75rem;
line-height: 150%;
color: #382924;
}

.prev-btn{
  gap: 10px;
  border: 2px grey solid;
  width: 20px;
}
.prev-btn :hover{
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  background: #6C7D02;
  border-radius: 50%;
}
.next-btn :hover{
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  background: #6C7D02;
  border-radius: 50%;
  border: #6C7D02;
}

.prev-btn , .next-btn > p{
  font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;

  color: #382924;
}

.next-btn{
  display: flex;
  align-items: center;
  gap: 10px;
}

.carousel__list8 {
 
  display: flex;
  list-style: none;
  padding: 0;
  padding: 1rem 0 0;
  overflow: hidden;
  position: relative;
  width: 90vw;
  margin: 0 auto;
  max-width: 70rem;
}

.carousel__item8 {
  max-width: 45%;
  padding: 1rem;
  flex-basis: 100%;
}

.slide-card-num{
  font-family: 'Noto Serif';
font-style: normal;
position: absolute;
margin-left: 9.5%;
margin-top: -2%;
font-weight: 700;
font-size: 1.5rem;
line-height: 170%;
width: 10%;
text-align: left;
color: rgba(0, 0, 0, 0.1);
}

.slide-card-text1{
  float: left;
  font-family: 'Urbanist', sans-serif; 
  font-weight: 600;
font-size: 1rem;
line-height: 21px;
z-index: 10;
margin-top: 3vh;
margin-left: 4%;
}
.slide-card-text3{
  float: left;
  font-family: 'Urbanist', sans-serif; 
  font-weight: 600;
font-size: 0.8rem;
line-height: 21px;
z-index: 10;
margin-top: 2.5vh;
margin-left: 5%;
text-align: left;
width: 90%;
}

.slide-card-text2{
  position: relative;
  font-family: 'Urbanist', sans-serif; 
float: left;
text-align: left;
margin-left: 5%;
margin-top: -4vh;
font-style: normal;
font-weight: 400;
font-size: 0.8rem;
line-height: 150%;
color: #382924;
z-index: 10;

}

.slide-card-img{
  width: 2.5%;
  position: absolute;
  z-index: 1;
  margin-left: 18%;
  margin-top: 2vh;
}

@media (max-width: 700px) {
  .carousel__list8 {
    display: flex;
    list-style: none;
    padding: 0;
    padding: 1rem 0 0;
    overflow: hidden;
    position: relative;
    width: 80vw;
    margin: 0 auto;
    max-width: 50rem;
   
  }

  .carousel__item8 {
    flex: 0 0 auto;
    width: 100%;
    padding: 0;
  }
}


@media (max-width: 500px) {
  .test-title{
    font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 150%;
  color: #382924;
  margin: 0 0 1rem 0;
  }

  .carousel__list8 {
    display: flex;
    list-style: none;

    padding: auto;
    overflow: hidden;
    position: relative;
    column-gap: 200px;
   
   
    margin: auto;

   
  }

  .carousel__item8 {
    flex: 0 0 auto;
    width: 100%;
    
    
  }

  .slide-card-text1{
    font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 700;
 
  font-size: 1.35rem;
  line-height: 150%;
  color: #382924;
  margin-bottom: 1rem;

  }
  
  .slide-card-text2{
    font-family: 'Noto Serif';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 150%;
  
  color: #382924;
  }
  
  .slide-card-num{
    font-family: 'Noto Serif';
  font-style: normal;
  position: absolute;
  margin-left: 2%;
  margin-top: -3%;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 170%;
  width: 15%;
  text-align: left;
  color: rgba(0, 0, 0, 0.1);
  }

}

.slide-card-main{
  background: white;
  border-radius: 15px;
  width: 252px;
  height: 423px;
}