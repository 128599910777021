// @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');

// .second-main{
//     background: #FFC000;
//     width: 100%;
//     height: 100vh;
//     z-index: -10;
// }

// .round{
//     border-radius: 50%;
//     position: sticky;
//     float: right;
//     background-color: #FFD146;
//     height: 80vh;
//     width: 40vw;
//     margin-right: -15vw;
//     margin-top: -10vw;
// }
// .second-heading{
//     font-family: 'Quicksand', sans-serif;
//     font-size: 2.5rem;
//     width: 55%;
//     margin-top: 5vh;
//     padding-top: 5vh;
// }

// @media screen and (max-width:500px) {
//     .second-heading{
//         font-size: 2rem;
//     }
// }


@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');

.secondmain {
  background: #FFC000;
  width: 100%;
  height: 70vh;
  z-index: -10;

  .round {
    border-radius: 50%;
    position: sticky;
    float: right;
    background-color: #FFD146;
    height: 80vh;
    width: 40vw;
    margin-right: -15vw;
    margin-top: -10vw;
  }

  .second-heading {
    font-family: 'Quicksand', sans-serif;
    font-size: 2.5rem;
    width: 55%;
    margin-top: 5vh;
    padding-top: 5vh;
  }

  .image{
    width: 60%;
  }

  @media screen and (max-width: 500px) {
    .second-heading {
      font-size: 2rem;
    }
    .image{
        width: 80%;
      }
  }
}
