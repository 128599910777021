.fourth-heading{
    font-family: Quicksand;
    font-size: 3rem;
}
.fourth-text{
    font-family: 'Urbanist', sans-serif;
    font-size: 1.2rem;
}
.vect{
    position: sticky;
    margin-left: -60px;
}
.f-card{
    border: 1px solid lightgray;
    border-radius: 10px;
    width: 100%;
}

.F-left-section{
    width: 50%;
    column-gap: 4%;
}
.F-right-section{
    width: 50%;
    column-gap: 4%;

}
.F-main-sec{
    column-gap: 2%;
}
.firstcard{
    padding: 15px 15px;
}
.price{
    font-family: 'Urbanist', sans-serif;
    font-size: 3rem;
}
.inr{
    font-family: 'Urbanist', sans-serif;
    font-size: 1.6rem;
}
.pricediv{
    padding-left: 15px;
    margin-top: -20px;
}
.ab{
    margin-top: 0.75rem;
}
.f-card-button{
    color: #6C7D02;
    border: 1px solid #6C7D02;
    border-radius: 5px;
    width: 80%;
    margin-left: 10%;
    height: 6vh;
    padding-left: 27%;
}

.f-card-button:hover{
    background-color: #6C7D02;
    color: white;
    border: 1px solid #6C7D02;
    border-radius: 5px;
    width: 80%;
    margin-left: 10%;
    height: 6vh;
    transition: all ease-in-out 500ms;
}
.leaf{
    position: relative;
    left: 250%;
}
.leaf2{
    position: relative;
    left: 10vw;
}
.leaf3{
    position: relative;
    left: 10vw;
}
@media screen and (max-width:1148px) {
    .F-main-sec{
        display: flex;
        flex-direction: column;
    }
    .F-left-section{
        width: 100%;
        column-gap: 4%;
    }
    .F-right-section{
        width: 100%;
        column-gap: 4%;
        margin-top: 5%;
    
    }
    .leaf{
        position: relative;
        left: 18vw;
    }
    .leaf2{
        position: relative;
        left: 22vw;
    }
    .leaf3{
        position: relative;
        left: 22vw;
    }
}
@media screen and (max-width:666px) {
    .F-main-sec{
        display: flex;
        flex-direction: column;
    }
    .F-left-section{
        display: flex;
        flex-direction: column;
        width: 100%;
        column-gap: 4%;
    }
    .F-right-section{
        display: flex;
        flex-direction: column;
        width: 100%;
        column-gap: 4%;
        margin-top: 5%;
    
    }
    .f-card{
        border: 1px solid lightgray;
        border-radius: 10px;
        width: 100%;
        margin-top: 5%;
    }
}


