
.container{
    display: flex;
    height: 85vh;
    width: 100%;
    background-image: radial-gradient(#eef38f 2px, transparent 2px);
    background-size: 30px 30px;


    @media screen and (max-width:500px) {
        height: 100vh;

    }

    .secleft{
        width: 50%;
        @media screen and (max-width:800px) {
                width: 100%;
                
        }

        .fleximagediv{
            width: 65%;
            margin-left: 15%;
            display: flex;
            margin-top: 5vh;
        }

        .maincontent{
            width: 80%;
            margin-left: 15%;
            margin-top: 10%;

            

            .heading{
                font-size: 2.5rem;
    font-family: 'Urbanist', sans-serif;
    line-height: 62.4px;

            }
            .text{
                font-size: 1.5rem;
    font-family: 'Urbanist', sans-serif;
    font-weight: 100;

            }
        }
    }

    .secright{
        width: 50%;
        @media screen and (max-width:800px) {
            width: 0%;
            
            
    }
    .lower{
        float: right;
        width: 75%;
        height: 100%;
    }
    .upper{
        width: 70%;
        float: right;
        margin-top: -95vh;
    }
    }
}