.container{
    height: 25vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-image: radial-gradient(#eef38f 2px, transparent 2px);
    background-size: 30px 30px;

    .heading{
        padding-top: 10vh;
        font-size: 2.5rem;
        font-weight: 700;
        font-family: 'Quicksand', sans-serif;

    }}